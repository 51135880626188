import AutonomousEnterpriseCard from "./AutonomousEnterpriseCard";
import { enterpriseCard } from "../common/Helper";
const AutonomousEnterprise = () => {
  return (
    <section className="main-container 2xl:py-[120px] 1xl:py-[100px] lg:py-20 py-16 ">
      <h2 className="heading text-kuroi text-center">
        Key Pillars of the Autonomous Enterprise
      </h2>
      <p className="text-center sub-heading md:pt-4 pt-3 lg:pb-[60px] md:pb-10 sm:pb-6 pb-4 max-w-[934px] mx-auto">
        The autonomous enterprise transcends mere automation, harmonizing
        operations for seamless functionality. It fosters collaboration between
        technology and people towards achieving safe, resilient, agile, and
        intelligent operations.
      </p>
      <div className="grid md:grid-cols-2 grid-cols-1">
        {enterpriseCard.map((item, index) => (
          <AutonomousEnterpriseCard key={index} item={item} index={index} />
        ))}
      </div>
    </section>
  );
};

export default AutonomousEnterprise;
