import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; 
import PrimaryButton from "./PrimaryButton"; 
import ContactUs from "./ContactUs";

const useterms = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleopenContactUsModal = () => {
    setIsOpen(true);
  };

  const handlecloseContactUsModal = () => {
    setIsOpen(false);
  };

  // Access the environment variable
  const baseUrl = process.env.REACT_APP_NEW_BASE_URL;

  return (
    <div className="w-full background-pattern p-8" style={{ marginBottom: '80px' }}>
      <Helmet>
        <title>Terms of Use | Actionabl</title>
        <meta name="description" content="Welcome to Actionabl’s website! By accessing and using this site, you completely agree to adhere to the terms and conditions outlined herein." />
        <meta property="og:title" content="Terms of Use | Actionabl" />
        <meta property="og:description" content="Welcome to Actionabl’s website! By accessing and using this site, you completely agree to adhere to the terms and conditions outlined herein." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.actionabl.ai/terms-of-use" />
        <meta property="og:image" content={`${baseUrl}/og-img/ActionablTermsofUse.jpg`} />
        <meta name="twitter:image" content={`${baseUrl}/og-img/ActionablTermsofUse.jpg`} />
        <meta name="twitter:title" content="Terms of Use | Actionabl" />
        <meta name="twitter:description" content="Welcome to Actionabl’s website! By accessing and using this site, you completely agree to adhere to the terms and conditions outlined herein." />
      </Helmet>
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-satoshi font-extrabold text-gray-800 mb-4 inline h-2 w-2">Terms of Use</h1>
        <p className="text-gray-800 mb-4">
          Welcome to Actionabl’s website. By accessing and using this site, you
          fully agree to comply with the terms and conditions. If you do not
          accept any part of these terms, you must not continue to use this
          website.
        </p>
        <p className="text-gray-800 mb-4">
          To use this website, you must be at least 18 years old. By using the
          site, you confirm that you meet this age requirement and agree to be
          bound by these terms.
        </p>
        <p className="text-gray-800 mb-4">
          In addition, this website uses cookies to improve and personalize your
          browsing experience. By continuing to use the site, you consent to the
          use of cookies in line with Actionabl’s
          <Link to="/PrivacyPolicy" className="text-blue-600 hover:underline">
            Privacy Policy
          </Link>
          , which can be reviewed here. These cookies help us improve
          functionality and tailor content to suit your preferences.
        </p>
        <PrimaryButton
        onClick={handleopenContactUsModal}
        className="w-full max-w-[140px] xs:max-w-[180px] sm:max-w-[200px] md:max-w-[220px] lg:max-w-[240px] block"
      >
        Get Started
      </PrimaryButton>

        <div className="mt-4">
          <Link to="/" className="text-blue-500 hover:underline">
            Home
          </Link>
          <span className="text-gray-600 mx-2"> &gt; </span>
          <span className="text-gray-800">Terms of Use</span>
        </div>
      </div>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </div>
  );
};

export default useterms;
