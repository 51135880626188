import { HeroFutureIcon } from "../common/Icons";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
const Hero = () => {
  return (
    <section className="flex flex-col items-center justify-center max-w-[900px] w-full mx-auto 3xl:pt-[215px] 2xl:pt-[180px] xl:py-[160px] lg:pt-[120px] pt-20 3xl:pb-[304px] 2xl:pb-[240px] xl:pb-[190px] lg:pb-[140px] pb-20 z-10 main-container hero-bg">
      <h1 className="hero-heading text-white ">
        Shaping the
        <span className="relative mx-3">
          Future
          <HeroFutureIcon
            className={
              "absolute -right-2 xl:-top-12 xs:-top-14 -top-[70px] z-[-1] pointer-events-none w-[140%] xs:w-[125%]"
            }
          />
        </span>
        of Autonomous Enterprise
      </h1>
      <p className="hero-sub-heading md:mt-6 mt-3 lg:mb-[98px] sm:mb-16 mb-10 text-center">
        Elevate your Business to New Heights of Efficiency and Innovation.
      </p>
      <div className="flex xs:flex-row flex-col items-center justify-center gap-3 w-full">
        {/* <PrimaryButton className={"max-w-[180px] w-full 3xl:text-xl"}>
          Explore more
        </PrimaryButton> */}
        <SecondaryButton className={"max-w-[180px] w-full 3xl:text-xl"}>
          Schedule
        </SecondaryButton>
      </div>
    </section>
  );
};

export default Hero;
