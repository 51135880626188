import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../common/Header";
import Footer from "../common/Footer";
import AutonomousEnterprise from "../homepage/AutonomousEnterprise";
import WhatMakes from "../homepage/WhatMakes";
import KeyBenefits from "../homepage/KeyBenefits";
import CoreCapabilities from "../homepage/CoreCapabilities";
import AllRightReserved from "../common/AllRightReserved";
import ScrollTop from "../common/ScrollTop";
import Hero from "../homepage/Hero";
import Ecosystem from "../homepage/ActionablEcosystem";

const Homepage = () => {
  // Use the environment variable REACT_APP_NEW_BASE_URL
  const apiUrl = process.env.REACT_APP_NEW_BASE_URL;

  return (
    <>
      <Helmet>
        <title>Empowering Autonomous Enterprise | Business Orchestration | Actionabl</title>
        <meta name="description" content="Actionabl is an end-to-end Business Orchestration platform designed to make your enterprise operations fully autonomous." />

        {/* Open Graph Meta Tags for LinkedIn and Facebook */}
        <meta property="og:title" content="Empowering Autonomous Enterprise | Business Orchestration | Actionabl" />
        <meta property="og:description" content="Actionabl is an end-to-end Business Orchestration platform designed to make your enterprise operations fully autonomous." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${apiUrl}/`} />
        <meta property="og:image" content={`${apiUrl}/og-img/ActionablHompage.jpg`} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Empowering Autonomous Enterprise | Business Orchestration | Actionabl" />
        <meta name="twitter:description" content="Actionabl is an end-to-end Business Orchestration platform designed to make your enterprise operations fully autonomous." />
        <meta name="twitter:image" content={`${apiUrl}/og-img/ActionablHompage.jpg`} />
        <meta name="twitter:card" content="summary_large_image" />  {/* Ensure this is set for larger images */}
      </Helmet>

      <Hero />
      <AutonomousEnterprise />
      <WhatMakes />
      <CoreCapabilities />
      <KeyBenefits />
      <Ecosystem />
      <ScrollTop />
    </>
  );
};

export default Homepage;
