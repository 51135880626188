const SecondaryButton = ({ className, children, onClick }) => {
  return (
    <a href="https://actionabl.hbportal.co/schedule/65c7d7b69de8660025d65ed7" target="_blank" rel="noopener noreferrer">
    <button
      onClick={onClick}
      className={`secondary-button overflow-hidden group w-full  ${className}`}
    >
      {children}
    </button>
  </a>
  );
};

export default SecondaryButton;
