import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import ContactUs from "./ContactUs";

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  // Using the REACT_APP_NEW_BASE_URL environment variable
  const newBaseUrl = process.env.REACT_APP_NEW_BASE_URL;

  const handleopenContactUsModal = () => {
    setIsOpen(true);
  };

  const handlecloseContactUsModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Actionabl</title>
        <meta 
          name="description" 
          content="Protecting our visitors' privacy is a top priority. We are committed to informing website users about our policies regarding the collection, use, and disclosure of personal information." 
        />
        <meta 
          property="og:title" 
          content="Privacy Policy | Actionabl" 
        />
        <meta 
          property="og:description" 
          content="Protecting our visitors' privacy is a top priority. We are committed to informing website users about our policies regarding the collection, use, and disclosure of personal information." 
        />
        <meta 
          property="og:type" 
          content="website" 
        />
        <meta 
          property="og:url" 
          content={`${newBaseUrl}/privacy-policy`}  // Using REACT_APP_NEW_BASE_URL here
        />
        <meta 
          property="og:image" 
          content={`${newBaseUrl}/og-img/ActionablPrivacyPolicy.jpg`}  // Using REACT_APP_NEW_BASE_URL here
        />
        <meta 
          name="twitter:image" 
          content={`${newBaseUrl}/og-img/ActionablPrivacyPolicy.jpg`}  // Using REACT_APP_NEW_BASE_URL here
        />
        <meta 
          name="twitter:title" 
          content="Privacy Policy | Actionabl" 
        />
        <meta 
          name="twitter:description" 
          content="Protecting our visitors' privacy is a top priority. We are committed to informing website users about our policies regarding the collection, use, and disclosure of personal information." 
        />
      </Helmet>

      <div>
        <div className="w-full background-pattern p-8" style={{ marginBottom: '80px' }}>
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h1 className="text-3xl font-satoshi font-extrabold text-gray-800 mb-4">
              Privacy Policy
            </h1>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              This page is dedicated to informing our website visitors about our
              policies concerning the collection, use, and disclosure of Personal
              Information for those who choose to use the website.
            </p>

            <PrimaryButton
        onClick={handleopenContactUsModal}
        className="w-full max-w-[140px] xs:max-w-[180px] sm:max-w-[200px] md:max-w-[220px] lg:max-w-[240px] block"
      >
        Get Started
      </PrimaryButton>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-8 mt-10">
            <div className="mt-4">
              <Link to="/" className="text-blue-500 hover:underline">
                Home
              </Link>
              <span className="text-gray-600 mx-2"> &gt; </span>
              <span className="text-gray-800">Privacy Policy</span>
            </div>
            <p className="text-gray-800 font-satoshi font-normal mb-4 mt-5">
              Protecting the privacy of our visitors is one of our top priorities.
              This Privacy Policy outlines the types of information collected and
              recorded by Actionabl, as well as how we utilize that information.
            </p>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              By opting to use our service, you consent to the collection and use
              of information in accordance with this policy. Any Personal
              Information we collect will be used solely for providing and
              enhancing our services. We commit to not using or sharing your
              information with third parties, except as explicitly outlined in
              this Privacy Policy.
            </p>

            <h1 className="text-2xl font-satoshi font-extrabold text-gray-800 mb-4">
              Information Collection and Usage
            </h1>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              To provide you with a better experience while using our website
              services, we may require you to share certain personally
              identifiable information, such as your Full Name, Email, Phone
              Number, and Company. The information collected will be utilized to
              contact or identify you, contingent upon your approval provided
              online. We prioritize transparency and user control, ensuring that
              you have the option to manage your information preferences
              effectively.
            </p>

            <h1 className="text-2xl font-satoshi font-extrabold text-gray-800 mb-4">
              Cookies
            </h1>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              At Actionabl, we use cookies to enhance your browsing experience.
              These cookies store information such as visitors' preferences and
              the specific pages accessed on our website. This data allows us to
              optimize your experience by tailoring web content based on your
              browser type, preferences, and other relevant information.
            </p>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              You have full control over the use of cookies, with the option to
              accept or refuse them and to be notified when a cookie is sent to
              your device. However, please note that if you choose to disable
              cookies, some features of our service may not function properly,
              potentially limiting your overall experience.
            </p>
            <p className="text-gray-800 font-satoshi font-normal mb-4">
              We prioritize your privacy and strive to make our services as
              user-friendly as possible while ensuring optimal performance.
            </p>
          </div>

          <ContactUs
            isOpen={isOpen}
            handlecloseContactUsModal={handlecloseContactUsModal}
          />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
